<template>
  <v-container fluid>
    <PageNavigation :items="items" />
    <v-row>
      <v-col cols="12">
        <base-material-card
          :title="$vuetify.lang.t('$vuetify.factoryMgr')"
          icon="mdi-factory"
        >
          <v-sheet>
            <v-toolbar-items class="d-flex align-center">
              <v-row>
                <v-col lg="6">
                  <v-text-field
                    :label="$vuetify.lang.t('$vuetify.search')"
                    v-model="search"
                    append-icon="mdi-magnify"
                  />
                </v-col>
              </v-row>
              <v-spacer />
              <v-btn color="green" elevation="2" @click="handleDialog"
                ><v-icon left>mdi-account-plus</v-icon
                >{{ $vuetify.lang.t("$vuetify.add") }}</v-btn
              >
            </v-toolbar-items>
          </v-sheet>

          <GeneralTable
            :head="headers"
            :data="datas"
            :search="search"
            :dialog.sync="dialog"
            v-on:edititem="handleDialogEdit"
            v-on:deleteitem="handleDeleteDialog"
          />
        </base-material-card>

        <factoryCRUD
          :dialog.sync="dialog"
          :myObj="obj"
          @handleData="handleFactory"
        />
        <DialogDelete
          :dialogDelete.sync="dialogDelete"
          :deleteObj="deleteObj"
          @handleDelete="handleDeleteConfirm"
        />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { factoryList, factoryUpsert, factoryDel } from "@/api/basicdata";
export default {
  name: "factory",
  components: {
    PageNavigation: () => import("@/components/pageNavigation"),
    GeneralTable: () => import("../../../../components/tableImg"),
    factoryCRUD: () => import("@/components/purchase/factoryCRUD"),
    DialogDelete: () => import("@/components/deleteDialog"),
  },
  data() {
    return {
      items: [
        { text: this.$vuetify.lang.t("$vuetify.home") },
        { text: this.$vuetify.lang.t("$vuetify.purchaseMgr") },
        { text: this.$vuetify.lang.t("$vuetify.factoryMgr") },
      ],
      search: "",
      headers: [
        {
          text: this.$vuetify.lang.t("$vuetify.itemNo"),
          value: "itemNo",
        },
        {
          text: this.$vuetify.lang.t("$vuetify.companyName"),
          value: "company",
        },
        {
          text: this.$vuetify.lang.t("$vuetify.contactPerson"),
          value: "contact",
        },
        {
          text: this.$vuetify.lang.t("$vuetify.contactPhone"),
          value: "tel",
        },
        {
          text: this.$vuetify.lang.t("$vuetify.contactEmail"),
          value: "email",
        },
        {
          text: this.$vuetify.lang.t("$vuetify.fax"),
          value: "fax",
        },
        {
          text: this.$vuetify.lang.t("$vuetify.address"),
          value: "address",
        },
        {
          text: this.$vuetify.lang.t("$vuetify.note"),
          value: "note",
        },
        {
          text: this.$vuetify.lang.t("$vuetify.opt"),
          value: "actions",
          sortable: false,
          align: "center",
        },
      ],
      datas: [],
      dialog: false,
      dialogDelete: false,
      obj: {
        id: "",
        company: "",
        contact: "",
        tel: "",
        email: "",
        fax: "",
        address: "",
        note: "",
      },
      deleteObj: {
        id: "",
        company: "",
        contact: "",
        tel: "",
        email: "",
        fax: "",
        address: "",
        note: "",
      },
      updataData: {
        id: "",
        company: "",
        contact: "",
        tel: "",
        email: "",
        fax: "",
        address: "",
        note: "",
      },
      wait: false,
    };
  },
  methods: {
    handleDialog() {
      this.dialog = true;
      this.obj = {
        id: "",
        company: "",
        contact: "",
        tel: "",
        email: "",
        fax: "",
        address: "",
        note: "",
      };
      this.updataData = {
        id: "",
        company: "",
        contact: "",
        tel: "",
        email: "",
        fax: "",
        address: "",
        note: "",
      };
    },
    handleFactory(obj) {
      this.dialog = false;
      this.updataData = obj;
      this.wait = true;
    },
    handleDialogEdit(obj) {
      this.dialog = true;
      // this.handleDialog();
      this.obj = obj;
    },
    handleDeleteDialog(obj) {
      this.dialogDelete = true;
      this.deleteObj = obj;
    },
    handleDeleteConfirm(obj) {
      this.dialogDelete = false;
      factoryDel(obj.id)
        .then(() => {
          this.datas.splice(this.datas.indexOf(obj), 1);
        })
        .catch((err) => console.log("Unable to Delete ", err));
    },
    getData() {
      factoryList()
        .then((data) => {
          this.datas = [...data.items];
          for (var i = 0; i < this.datas.length; i++) {
            this.datas[i].itemNo = i + 1;
          }
        })
        .catch((err) => console.log(err));
    },
  },
  mounted() {
    this.getData();
  },
  watch: {
    dialog: function () {
      this.getData();
    },
    wait: function () {
      if (this.wait == true) {
        factoryUpsert(this.updataData)
          .then(() => {
            this.getData();
            this.wait = false;
          })
          .catch((err) => console.log("New Factory: ", err));
      }
    },
  },
};
</script>

<style>
</style>